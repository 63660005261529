/* UniversitiesPage.css */

.sidebar .nav-link-custom {
  color: white;
}

.sidebar .nav-link-custom:hover {
  color: #3399ff; /* This is the color you want on hover */
}
.sidebar .nav-link-custom.active {
  color: #3399ff;
}

/* Style for the navigation cards on the left */
.nav-card {
  border: none;
  border-right: 5px solid; /* Grey bar on the right */
  text-align: center;
  font-size: 1.2em; /* Bigger text */
  color: "white";
  padding: 1em; /* More space inside the card */
  margin-bottom: 0.5em; /* Spacing between cards */
}

/* Remove the Bootstrap Card border */
.card {
  border: none;
}

/* Style for the active or hovered card */
.nav-card-active,
.nav-card:hover {
  background-color: #333399; /* Light grey background for active/hover state */
}

/* Style for the container of the cards to remove space between them */
.card-container {
  padding-right: 0;
  padding-left: 0;
}

/* Style for the main container to adjust the layout */
.main-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  /* Desktop and larger screens */
  .main-container {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .sidebar {
    min-height: 80vh;
  }
}
/* Style for the search box to center it */
.search-box-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  padding-top: 1em;
  background-color: #333399;
}

.align-toggle-right {
  margin-left: auto;
}

/* Additional responsiveness and spacing adjustments might be required */
@media (max-width: 991px) {
  .sidebar .navbar {
    background-color: #333399; /* Example background color */
  }
  .sidebar .nav-link {
    padding: 0.5rem 1rem; /* Smaller padding for dropdown links */
  }
}
