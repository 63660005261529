.profile-container .image-hover-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container .profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-container .edit-icon-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5); /* Faint background */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.profile-container .edit-icon-overlay:hover {
  opacity: 1; /* Icon appears on hover */
}

.profile-container .edit-icon {
  display: none; /* Icon is not displayed until hovered */
}

.profile-container .image-hover-wrapper:hover .edit-icon {
  display: block; /* Icon is displayed on hover */
}
