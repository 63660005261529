/* SignInPage.css */

/* Main container for the SignInPage */
.sign-in-page {
  display: flex;
  height: 100vh;
}

/* Left side with blue background */
.left-side {
  flex: 0.4;
  background-color: #3333ff;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
}

.left-side h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.left-side ul {
  list-style-type: disc;
  margin-left: 20px;
  padding: 0;
}

.left-side li {
  margin-bottom: 10px;
}

/* Right side with lighter blue background */
.right-side-login {
  flex: 1;
  background-color: #3399ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login box */
.login-box {
  width: 65%;
  padding: 20px;
  background-color: white;
  border-radius: 8px; /* Curved edges for the login box */
}

.or-sign-in-with {
  text-align: center;
}

/* Rent Scoop Logo */
.rent-scoop-logo-login {
  width: 200px;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto;
}
/* Username and Password fields */
.login-label {
  /* display: block; */
  text-align: left; /* Align labels to the left */
  margin-bottom: 5px;
}

.custom-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Curved edges for input boxes */
}
.login-buttons {
  width: 100%;
  padding: 10px;
  background-color: #3333ff;
  color: white;
  border: none;
  border-radius: 8px; /* Curved edges for buttons */
  cursor: pointer;
  margin-bottom: 15px;
}
/* Google button */
.google-login-button {
  background-color: #ff5722; /* Change to reddish-orange color */
  color: white;
}
/* Bottom links */
.bottom-links {
  display: flex;
  flex-direction: column; /* Show links vertically for mobile view */
  align-items: center; /* Center links horizontally for mobile view */
  font-size: 14px;
}

.bottom-links a {
  text-decoration: underline;
  color: #3333ff;
  cursor: pointer;
  margin-top: 5px; /* Add some space between the links */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .sign-in-page {
    flex-direction: column; /* Stack the sections for mobile view */
  }

  .right-side-login {
    order: -1; /* Move the right side above the left side in mobile view */
  }

  .bottom-links {
    order: 2; /* Move the links below the login box in mobile view */
  }

  /* Center labels in the login box for mobile view */
  .login-box .login-label {
    text-align: left;
  }
}

.register-button {
  background-color: green; /* Change to reddish-orange color */
  color: white;
}
