.filter-bar {
  background-color: rgb(231, 231, 231);
}
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #e2e3e2;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
#scatterChart {
  margin-left: 5%;
  margin-right: 5%;
}
.rent-price {
  width: 22vw;
}
/* Mapbox CSS */
.map-container {
  height: 80vh;
}

@media screen and (max-width: 768px) {
  .map-container {
    height: 40vh;
  }
}

.mapboxgl-popup-content {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(45, 45, 45, 0.7);
  margin-bottom: -10px;
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-marker {
  width: 44px;
  height: 22px;
  border: 1px;
  border-radius: 1px;
  background-color: white;
  padding: 2px 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-align: center; /* Add this line to center the text */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapboxgl-canvas {
  border-radius: 0px;
}

.mapboxgl-marker span {
  margin: 0 5px;
}
/* End Mapbox CSS */

/* Initial Popup CSS */
.background {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.add-yours-popup-button {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: rgb(0, 199, 32) !important;
  --bs-btn-border-color: rgb(0, 199, 32);
  --bs-btn-hover-bg: rgb(0, 170, 22) !important;
  --bs-btn-focus-shadow-rgb: 0, 132, 20 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-focus-box-shadow: 0 199 32 0.25rem rgba(rgb(0, 199, 32), 0.5) !important;
  --bs-btn-active-bg: rgb(0, 199, 32);
  --bs-btn-active-border-color: rgb(0, 199, 32);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(0, 199, 32);
  --bs-btn-disabled-border-color: rgb(0, 199, 32);
}

/* End Popup CSS */

/* ag-grid css */

.centered {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.centered-header {
  justify-content: center;
  text-align: center;
  font-size: larger;
}

.ag-header-row {
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 36px !important;
}

.ag-theme-material .ag-header-cell-label {
  justify-content: center;
  text-align: center;
}
/* .ag-theme-material .ag-header-container {
  background-color: #b0b0b0;
} */
.ag-theme-material .ag-row-even {
  background-color: #f9f9f9 !important;
}

.ag-theme-material .ag-row-odd {
  background-color: #ffffff !important;
}
@media (max-width: 768px) {
  .centered {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .centered {
    width: 50%;
  }
}

.ag-theme-material {
  font-size: 20pt;
  font-family: inherit;
  --ag-checkbox-checked-color: var(--ag-material-primary-color);
  --ag-header-foreground-color: var(--ag-foreground-color);
  --ag-row-group-indent-size: calc(
    var(--ag-grid-size) * 1 + var(--ag-icon-size)
  );
}
.ag-theme-material .ag-header {
  font-size: 16pt !important;
  font-weight: 400 !important;
  text-align: center;
  height: 40px !important;
}

.ag-theme-material .ag-row {
  font-size: 12pt;
  text-align: left;
  vertical-align: middle;
  padding-top: 0pt;
  /* height: 20px; */
}
.ag-row-focus {
  background-color: rgb(
    0,
    255,
    0,
    0.3
  ) !important; /* Change to desired color */
}

.ag-row-selected {
  background-color: rgb(
    0,
    255,
    0,
    0.3
  ) !important; /* Change to desired color */
}
.ag-theme-material .ag-cell {
  line-height: 34px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left;
  vertical-align: middle;
}
.ag-theme-material .ag-menu {
  border-radius: 0px;
}
.ag-theme-material .ag-simple-filter-body-wrapper {
  padding: 6px 6px;
  padding-bottom: 0px;
}
.ag-theme-material .ag-select .ag-picker-field-wrapper {
  min-height: 20px;
}
.ag-theme-material .ag-picker-field-wrapper {
  border-radius: 0px;
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  border-radius: 0px;
  /* min-height: 20px; */
}
.ag-theme-material .ag-select .ag-picker-field-display {
  margin: 0px 4px;
}
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-left: 2px;
  padding-right: 2px;
  height: 36px !important;
}
.ag-floating-filter-full-body {
  margin-left: -2px;
  margin-right: -2px;
}
.ag-theme-material .ag-paging-panel {
  height: 25px;
}
.ag-theme-material .ag-row {
  border-width: 1px;
}
.ag-theme-material .ag-ltr .ag-cell {
  /* border: 1px solid #dde2eb; */
}
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  /* border: 1px solid #dde2eb; */
}
.ag-theme-material .ag-root-wrapper {
  border-radius: 0px;
}
.ag-theme-material .ag-filter-header-container {
  padding-bottom: 0px;
  margin: 2px 5px;
  line-height: 20px;
  height: 10px;
}
.ag-theme-material .ag-set-filter-item {
  margin: 0px 5px;
}
.ag-filter-checkbox input {
  height: 12px;
  width: 12px;
  vertical-align: middle;
}
.ag-filter-value {
  padding-left: 2px;
}
.ag-theme-material .ag-filter-apply-panel {
  padding: 5px;
}
.ag-theme-material .ag-ltr .ag-filter-apply-panel-button {
  margin-left: 5px;
}
.ag-theme-material .ag-standard-button {
  border-radius: 0px;
  padding: 0px 4px;
  font-size: 8pt;
}
.ag-theme-material .ag-simple-filter-body-wrapper > * {
  margin-bottom: 5px;
}
/* .ag-theme-material .ag-select:not(.ag-cell-editor) {
  height: 20px;
} */
.ag-theme-material .ag-radio-button-input-wrapper {
  font-size: 8pt;
}
.ag-theme-material .ag-header-cell-menu-button {
  opacity: 1 !important;
}
.ag-theme-material .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-material .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-material .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-material .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-material .ag-ltr input[class^="ag-"][type="date"],
.ag-theme-material .ag-ltr input[class^="ag-"][type="datetime-local"],
.ag-theme-material .ag-ltr textarea[class^="ag-"] {
  padding-left: 0px;
}
.ag-input-field-input.ag-text-field-input {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ag-theme-material .ag-checkbox-input-wrapper {
  font-size: 12px;
  line-height: 12px;
  width: 12px;
  height: 12px;
}
.ag-cell-wrapper {
  display: grid;
  /* white-space: pre-wrap; */
  align-items: flex-start;
}
.prewrap .ag-cell-wrapper {
  white-space: pre-wrap;
}
/* .ag-cell-wrapper .ag-selection-checkbox {
  white-space: nowrap;
} */
.ag-theme-material .ag-ltr .ag-row-group-indent-1 {
  padding-left: 0px;
}
.ag-cell-wrapper.ag-row-group {
  display: flex;
}
.ag-cell-wrapper.ag-row-group-leaf-indent {
  display: flex;
}
.ag-theme-material .ag-cell-inline-editing {
  height: 20px;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  vertical-align: middle;
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  min-height: 20px;
}
.ag-theme-material input[class^="ag-"]:not([type]):focus,
.ag-theme-material input[class^="ag-"][type="text"]:focus,
.ag-theme-material input[class^="ag-"][type="number"]:focus,
.ag-theme-material input[class^="ag-"][type="tel"]:focus,
.ag-theme-material input[class^="ag-"][type="date"]:focus,
.ag-theme-material input[class^="ag-"][type="datetime-local"]:focus,
.ag-theme-material textarea[class^="ag-"]:focus {
  box-shadow: none;
  /* border-color: #ccc; */
}
.text-center .ag-header-cell-label {
  font-size: 20pt;
  justify-content: center;
  text-align: center;
}
[col-id="qtyInvoiced"] .ag-header-cell-text {
  font-size: 20pt !important;
  font-weight: 300;
  text-decoration: underline;
}
[col-id="qtyAllocated"] .ag-header-cell-text {
  font-size: 20pt !important;
  font-weight: 300;
  text-decoration: underline;
}
.inv-dist-footer .ag-cell {
  font-weight: 500;
}
.ag-cell .mat-button,
.ag-cell .mat-raised-button,
.ag-cell .mat-icon-button,
.ag-cell .mat-stroked-button,
.ag-cell .mat-flat-button,
.ag-cell .mat-fab,
.ag-cell .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
/* .ag-cell .mat-icon-button i,
.ag-cell .mat-icon-button .mat-icon {
  line-height: 16px;
} */
/* .ag-cell .mat-icon {
  height: 16px;
  width: 16px;
} */
/* .ag-cell .material-icons {
  font-size: 12pt;
} */
.ag-cell .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
  font-size: 12pt;
}
.ag-cell .mat-button,
.ag-cell .mat-icon-button,
.ag-cell .mat-stroked-button,
.ag-cell .mat-flat-button {
  vertical-align: top;
}
.ag-cell.hyperlink {
  color: blue;
  text-decoration: underline;
}
.ag-cell.text-red {
  color: red;
}
.ag-cell .mat-form-field {
  border: none;
}
.ag-cell.bg-red {
  background-color: red;
}
.ag-cell-wrap-text {
  word-break: break-word;
}

app-ag-grid-date-picker .mat-button,
app-ag-grid-date-picker .mat-raised-button,
app-ag-grid-date-picker .mat-icon-button,
app-ag-grid-date-picker .mat-stroked-button,
app-ag-grid-date-picker .mat-flat-button,
app-ag-grid-date-picker .mat-fab,
app-ag-grid-date-picker .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
app-ag-grid-date-picker .mat-icon-button i,
app-ag-grid-date-picker .mat-icon-button .mat-icon {
  line-height: 16px;
}
app-ag-grid-date-picker .mat-icon {
  height: 16px;
  width: 16px;
}
app-ag-grid-date-picker .material-icons {
  font-size: 16px;
}
app-ag-grid-date-picker .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
}
app-ag-grid-date-picker .mat-button,
app-ag-grid-date-picker .mat-icon-button,
app-ag-grid-date-picker .mat-stroked-button,
app-ag-grid-date-picker .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-date-picker .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 18px;
  height: 18px;
}
app-ag-grid-date-picker .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-date-time-picker .mat-button,
app-ag-grid-date-time-picker .mat-raised-button,
app-ag-grid-date-time-picker .mat-icon-button,
app-ag-grid-date-time-picker .mat-stroked-button,
app-ag-grid-date-time-picker .mat-flat-button,
app-ag-grid-date-time-picker .mat-fab,
app-ag-grid-date-time-picker .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
app-ag-grid-date-time-picker .mat-icon-button i,
app-ag-grid-date-time-picker .mat-icon-button .mat-icon {
  line-height: 16px;
}
app-ag-grid-date-time-picker .mat-icon {
  height: 16px;
  width: 16px;
}
app-ag-grid-date-time-picker .material-icons {
  font-size: 16px;
}
app-ag-grid-date-time-picker .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
}
app-ag-grid-date-time-picker .mat-button,
app-ag-grid-date-time-picker .mat-icon-button,
app-ag-grid-date-time-picker .mat-stroked-button,
app-ag-grid-date-time-picker .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-date-time-picker .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 18px;
  height: 18px;
}
app-ag-grid-date-time-picker .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-date-renderer .mat-button,
app-ag-grid-date-renderer .mat-raised-button,
app-ag-grid-date-renderer .mat-icon-button,
app-ag-grid-date-renderer .mat-stroked-button,
app-ag-grid-date-renderer .mat-flat-button,
app-ag-grid-date-renderer .mat-fab,
app-ag-grid-date-renderer .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
app-ag-grid-date-renderer .mat-icon-button i,
app-ag-grid-date-renderer .mat-icon-button .mat-icon {
  line-height: 16px;
}
app-ag-grid-date-renderer .mat-icon {
  height: 16px;
  width: 16px;
}
app-ag-grid-date-renderer .material-icons {
  font-size: 16px;
}
app-ag-grid-date-renderer .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
}
app-ag-grid-date-renderer .mat-button,
app-ag-grid-date-renderer .mat-icon-button,
app-ag-grid-date-renderer .mat-stroked-button,
app-ag-grid-date-renderer .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-date-renderer .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
app-ag-grid-date-renderer .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-date-time-renderer .mat-button,
app-ag-grid-date-time-renderer .mat-raised-button,
app-ag-grid-date-time-renderer .mat-icon-button,
app-ag-grid-date-time-renderer .mat-stroked-button,
app-ag-grid-date-time-renderer .mat-flat-button,
app-ag-grid-date-time-renderer .mat-fab,
app-ag-grid-date-time-renderer .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
app-ag-grid-date-time-renderer .mat-icon-button i,
app-ag-grid-date-time-renderer .mat-icon-button .mat-icon {
  line-height: 16px;
}
app-ag-grid-date-time-renderer .mat-icon {
  height: 16px;
  width: 16px;
}
app-ag-grid-date-time-renderer .material-icons {
  font-size: 16px;
}
app-ag-grid-date-time-renderer .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
}
app-ag-grid-date-time-renderer .mat-button,
app-ag-grid-date-time-renderer .mat-icon-button,
app-ag-grid-date-time-renderer .mat-stroked-button,
app-ag-grid-date-time-renderer .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-date-time-renderer .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
app-ag-grid-date-time-renderer .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-month-picker .mat-button,
app-ag-grid-month-picker .mat-raised-button,
app-ag-grid-month-picker .mat-icon-button,
app-ag-grid-month-picker .mat-stroked-button,
app-ag-grid-month-picker .mat-flat-button,
app-ag-grid-month-picker .mat-fab,
app-ag-grid-month-picker .mat-mini-fab {
  font-size: 8pt !important;
  min-width: 20px !important;
  line-height: 16px !important;
  border-radius: 0px !important;
  /* border: 1px solid #ccc !important; */
}
app-ag-grid-month-picker .mat-icon-button i,
app-ag-grid-month-picker .mat-icon-button .mat-icon {
  line-height: 16px !important;
}
app-ag-grid-month-picker .mat-icon {
  height: 16px !important;
  width: 16px !important;
}
app-ag-grid-month-picker .material-icons {
  font-size: 16px !important;
}
app-ag-grid-month-picker .mat-icon-button {
  padding: 0px !important;
  line-height: 16px !important;
  height: 18px !important;
  width: 22px !important;
}
app-ag-grid-month-picker .mat-button,
app-ag-grid-month-picker .mat-icon-button,
app-ag-grid-month-picker .mat-stroked-button,
app-ag-grid-month-picker .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-month-picker .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
app-ag-grid-month-picker .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-month-renderer .mat-button,
app-ag-grid-month-renderer .mat-raised-button,
app-ag-grid-month-renderer .mat-icon-button,
app-ag-grid-month-renderer .mat-stroked-button,
app-ag-grid-month-renderer .mat-flat-button,
app-ag-grid-month-renderer .mat-fab,
app-ag-grid-month-renderer .mat-mini-fab {
  font-size: 8pt;
  min-width: 20px;
  line-height: 16px;
  border-radius: 0px;
  /* border: 1px solid #ccc; */
}
app-ag-grid-month-renderer .mat-icon-button i,
app-ag-grid-month-renderer .mat-icon-button .mat-icon {
  line-height: 16px;
}
app-ag-grid-month-renderer .mat-icon {
  height: 16px;
  width: 16px;
}
app-ag-grid-month-renderer .material-icons {
  font-size: 22px;
}
app-ag-grid-month-renderer .mat-icon-button {
  padding: 0px;
  line-height: 16px;
  height: 18px;
  width: 22px;
}
app-ag-grid-month-renderer .mat-button,
app-ag-grid-month-renderer .mat-icon-button,
app-ag-grid-month-renderer .mat-stroked-button,
app-ag-grid-month-renderer .mat-flat-button {
  vertical-align: top;
}
app-ag-grid-month-renderer .mat-datepicker-toggle-default-icon {
  margin: auto;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
app-ag-grid-month-renderer .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}

app-ag-grid-typeahead .mat-input-element {
  height: 18px;
  /* border: 1px solid #ccc;
  border-radius: 0px; */
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  /* vertical-align: bottom; */
  text-align: inherit;
}

.ag-theme-material .ag-cell-inline-editing app-ag-grid-date-picker {
  padding-left: 2px;
  padding-right: 2px;
}
.ag-theme-material .ag-cell app-ag-grid-date-picker {
  line-height: 18px;
}
.text-right .ag-input-field-input {
  text-align: right;
}
.ag-cell.form-control {
  background-color: #ececa7;
}
.ag-theme-material .ag-large-text-input {
  padding: 0px;
}
.ag-theme-material .ag-popup-editor {
  border-radius: 0px;
}
.ag-theme-material
  .ag-cell-wrapper
  > *:not(.ag-cell-value):not(.ag-group-value) {
  height: 18px;
}
.ag-theme-material .ag-ltr .ag-row-drag,
.ag-theme-material .ag-ltr .ag-selection-checkbox,
.ag-theme-material .ag-ltr .ag-group-expanded,
.ag-theme-material .ag-ltr .ag-group-contracted {
  margin-right: 0px;
}
.ag-theme-material .ag-status-bar {
  padding-left: 12px;
  padding-right: 12px;
  line-height: 20px;
}
.ag-theme-material .ag-status-name-value {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ag-theme-material .ag-ltr .ag-floating-filter-button {
  margin-left: 0px;
}
.ag-theme-material .ag-side-buttons {
  width: 20px;
}
.ag-theme-material .ag-menu-header {
  min-width: 80px;
}
.ag-theme-material .ag-tab {
  padding: 0px;
}
.ag-theme-material .ag-mini-filter {
  margin: 2px 2px;
}
.ag-theme-material .ag-list-item,
.ag-theme-material .ag-virtual-list-item {
  height: 20px !important;
}
.ag-theme-material .ag-column-select-header {
  height: 24px;
  padding: 0px 2px;
}
.ag-theme-material .ag-ltr .ag-column-group-icons:not(:last-child),
.ag-theme-material .ag-ltr .ag-column-select-header-icon:not(:last-child),
.ag-theme-material .ag-ltr .ag-column-select-header-checkbox:not(:last-child),
.ag-theme-material
  .ag-ltr
  .ag-column-select-header-filter-wrapper:not(:last-child),
.ag-theme-material .ag-ltr .ag-column-select-checkbox:not(:last-child),
.ag-theme-material
  .ag-ltr
  .ag-column-select-column-drag-handle:not(:last-child),
.ag-theme-material
  .ag-ltr
  .ag-column-select-column-group-drag-handle:not(:last-child),
.ag-theme-material .ag-ltr .ag-column-select-column-label:not(:last-child) {
  margin-right: 6px;
}
.ag-theme-material .ag-column-select-list {
  padding: 2px 2px;
}
.ag-theme-material .ag-column-select-column-group:not(:last-child),
.ag-theme-material .ag-column-select-column:not(:last-child) {
  margin-bottom: 2px;
}
.ag-theme-material .ag-menu-list {
  padding: 2px 0px;
}
.ag-theme-material .ag-ltr .ag-menu-option-icon,
.ag-theme-material .ag-ltr .ag-compact-menu-option-icon {
  padding-left: 2px;
}
.ag-theme-material .ag-menu-option-part,
.ag-theme-material .ag-compact-menu-option-part {
  padding: 0px;
}
.ag-theme-material .ag-menu-separator {
  height: 4px;
}
.ag-theme-material .ag-filter-toolpanel-header,
.ag-theme-material .ag-filter-toolpanel-search,
.ag-theme-material .ag-status-bar,
.ag-theme-material .ag-panel-title-bar-title,
.ag-theme-material .ag-side-button-button,
.ag-theme-material .ag-multi-filter-group-title-bar {
  color: var(--ag-header-foreground-color, rgba(0, 0, 0, 1));
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  height: inherit;
  padding-bottom: 0px;
}
.ag-theme-material .ag-tab {
  height: 20px;
}
.ag-theme-material .ag-cell-inline-editing {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ag-theme-material .ag-filter-toolpanel-group-level-0-header {
  height: 32px;
}
.ag-theme-material .ag-tabs-body {
  padding: 0px;
}
.ag-theme-material .ag-select:not(.ag-cell-editor) {
  height: inherit;
}
.ag-theme-material .ag-side-bar {
  /* border-left: 1px solid #e2e2e2; */
  border-left-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-material .ag-header {
  /* border-bottom: 1px solid #e2e2e2; */
  border-bottom-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-material .ag-root-wrapper {
  /* border: 1px solid #e2e2e2; */
  border-color: var(--ag-border-color, #e2e2e2);
}
.ag-theme-material .ag-icon {
  font-size: 15px;
  line-height: 15px;
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--ag-checkbox-checked-color, #016de0);
}
.ag-theme-material .ag-pivot-mode-panel {
  height: 40px;
}
.ag-theme-material .ag-filter-toolpanel-search {
  height: 40px;
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  /* border-bottom-width: 1px; */
}
.ag-theme-material .ag-details-row {
  padding: 5px;
}
.ag-theme-material
  .ag-ltr
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon {
  margin-left: 5px;
}
.ag-theme-material
  .ag-ltr
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon.ag-sort-ascending-icon {
  margin-left: 0px;
}
.ag-cell-label-container {
  padding: 0px;
}
.ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
  padding-left: calc(var(--ag-row-group-indent-size) * 1);
}
