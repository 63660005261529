.filter-bar {
  background-color: rgb(231, 231, 231);
}
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #e2e3e2;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
#scatterChart {
  margin-left: 5%;
  margin-right: 5%;
}
.rent-price {
  width: 22vw;
}
/* Mapbox CSS */
.map-container {
  height: 80vh;
}

@media screen and (max-width: 768px) {
  .map-container {
    height: 40vh;
  }
}

.mapboxgl-popup-content {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(45, 45, 45, 0.7);
  margin-bottom: -10px;
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-marker {
  width: 44px;
  height: 22px;
  border: 1px;
  border-radius: 1px;
  background-color: white;
  padding: 2px 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-align: center; /* Add this line to center the text */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapboxgl-canvas {
  border-radius: 0px;
}

.mapboxgl-marker span {
  margin: 0 5px;
}
/* End Mapbox CSS */

/* Initial Popup CSS */
.background {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything else */
}

.centered {
  position: relative;
  text-align: center;
  max-width: 600px; /* Set a max-width for the popup content */
  width: 100%;
}

.carousel-container {
  position: relative;
  background: none; /* No white background */
}

.close-icon {
  position: absolute;
  top: -40px; /* Adjust as needed */
  right: 0;
  cursor: pointer;
  color: white;
  font-size: 30px;
  z-index: 1001; /* Above the carousel */
}

.carousel-image {
  max-height: 400px; /* Adjust based on your need */
  object-fit: cover;
}

.listing-details {
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Slightly transparent background */
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%; /* Full width of the container */
  padding: 10px;
}

/* Additional custom styles for carousel controls */
.carousel-control-prev,
.carousel-control-next {
  width: 5%; /* Make sure the area for the controls is adequate */
}

/* Optional: Styles to adjust the arrows to the far edges */
.carousel-control-prev {
  left: -5%;
}

.carousel-control-next {
  right: -5%;
}

/* End Popup CSS */

.add-yours-popup-button {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: rgb(0, 199, 32) !important;
  --bs-btn-border-color: rgb(0, 199, 32);
  --bs-btn-hover-bg: rgb(0, 170, 22) !important;
  --bs-btn-focus-shadow-rgb: 0, 132, 20 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-focus-box-shadow: 0 199 32 0.25rem rgba(rgb(0, 199, 32), 0.5) !important;
  --bs-btn-active-bg: rgb(0, 199, 32);
  --bs-btn-active-border-color: rgb(0, 199, 32);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(0, 199, 32);
  --bs-btn-disabled-border-color: rgb(0, 199, 32);
}

/* End Popup CSS */

/* Standard Detail View */
.standard-detail-view .listing-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
