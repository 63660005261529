.nav-link,
.brand-link,
.dropdown-item {
  color: #3399ff;
}

.nav-link:hover,
.brand-link:hover,
.dropdown-item:hover {
  color: green;
}
.right-side {
  display: flex;
  justify-content: flex-end;
}
.top-left-logo {
  height: 38px;
}
.sign-in-navbar {
  margin-left: auto;
  margin-right: 50px;
}
